<template>
    <div class="templateEnvironmentalCultureScore">
      <div class="flex-box">
          <div class="left">
          </div>
      </div>
      <el-table
          :data="classMonthGradeList"
          style="width: 100%">
          <el-table-column
              label="年级"
              prop="gradeName"
              align="center"></el-table-column>
          <el-table-column
              label="状态"
              prop="statusStr"
              align="center">
              <!-- <template slot-scope="scope">
                  <div>{{scope.row.status == 2 ? '已完成' : '未完成'}}</div>
              </template> -->
          </el-table-column>
          <el-table-column
              label="操作"
              width="180px"
              align="center">
              <template slot-scope="scope">
                  <el-button size="mini" v-if="scope.row.status == 0" type="primary" @click="score(scope.row)">评 分</el-button>
                  <el-button size="mini" v-if="scope.row.status == 1" type="warning" @click="score(scope.row)">查 看</el-button
              >
              </template>
          </el-table-column>
      </el-table>
      <el-pagination
          layout="prev,pager,next"
          background
          :page-count="page.pageCount"
          :page-size="page.currentPage"
          @current-change="queryClassMonthGradeList"
      ></el-pagination>

      <el-dialog
        title=""
        :visible.sync="evaluationManagementScoreDialogVisible"
        width="820px"
      >
        <div class="center form-height">
          <div class="title">竹香学校班级环境文化评比表</div>
          <table style="width:100%" border="1" cellspacing="0" cellpadding="3">
            <thead>
              <tr>
                <th >班级</th>
                <th>项目</th>
                <th>{{evaluationMonth}}</th>
              </tr>
            </thead>

            <tbody>
              <template v-for="(item,index1) in evaluationManagementDetailList">
                <tr v-for="(jtem,index2) in item.classList" :key="index2">
                  <td v-if="index2 == 0" rowspan="4">{{jtem.cname}}</td>
                  <td>{{jtem.name}}</td>
                  <td class="evaluationScoreStyle">
                    <el-input @input="inputChange($event,4,'evaluationManagementDetailList','classList',index1,index2)" type="number" :max="4" :min="0" :disabled="evaluationManagementScoreDisabled" v-if="index2==0 || index2==2" v-model="jtem.score" placeholder="最多扣4分"></el-input>
                    <el-input @input="inputChange($event,6,'evaluationManagementDetailList','classList',index1,index2)" type="number" :max="6" :min="0" :disabled="evaluationManagementScoreDisabled" v-if="index2==1 || index2==3" v-model="jtem.score" placeholder="最多扣6分"></el-input>
                  </td>
                </tr>
              </template>

            </tbody>
          </table>
        </div>
        <span v-if="!evaluationManagementScoreDisabled" slot="footer" class="dialog-footer">
          <el-button @click="evaluationManagementScoreDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="evaluationManagementScoreConfirm">确 定</el-button>
        </span>
      </el-dialog>
       

    </div>
</template>

<script>
export default {
    name:'templateEnvironmentalCultureScore',
    data(){
        return {
            page: { pageCount: 1, currentPage: 0 },

            row:this.$route.query.row,
            month_id:Number(this.$route.query.id),
            yearid:this.$route.query.yearid,
            teacherId:JSON.parse(sessionStorage.getItem('userInfo')).userid,
            classMonthGradeList:[],
            evaluationManagementScoreDisabled:false,
            evaluationManagementData:[],
            evaluationManagementDetailList:[],
            evaluationMonth:'',
            begin_time:'',
            end_time:'',
            // 对话框显示与隐藏
            evaluationManagementScoreDialogVisible:false,
            // dialogTitle:''
        }
    },
    created(){
      this.queryClassMonthGradeList()
      this.evaluationMonth = this.row.month + '月'
      this.begin_time = this.row.begin_time
      this.end_time = this.row.end_time
      console.log(this.row)
      console.log(this.begin_time)
      console.log(this.end_time)
      // begin_time end_time
        
    },
    methods:{
      queryClassMonthGradeList(){
        let data = {
          month_id:this.month_id,
          yearid:this.yearid,
          teacherId:this.teacherId
        }
        this.$post(`/base/queryClassMonthGradeList.do`,data)
        .then(res=>{
          console.log(res)
          this.classMonthGradeList = res.data

          console.log(this.classMonthGradeList)
        })
      },
      inputChange(newValue,type,name,nextList,index1,index2){
        console.log(newValue,type)
        console.log(this.classCultureSummaryScoreDetailList)
        console.log(index1,index2)
        
        if(name == 'classCultureSummaryScoreDetailList'){
          this.getTotalScore()
        }

        if(Number(newValue) > Number(type) || Number(newValue) < 0){
          this[name].forEach((item,i)=>{
            item[nextList].forEach((jtem,j)=>{
              if(i == index1 && j == index2){
                jtem.score = ''
              }
              
            })
          })
        }
        
      },
      score(row){
        console.log(row)
        // this.queryClassMonthScoreByCheck()

        if(row.status == 1){
          this.queryClassMonthScoreByCheck(row)
          this.evaluationManagementScoreDisabled = true
        }else{
          this.evaluationManagementScoreDisabled = false
          if(this.isIncludedScoreTime(this.begin_time,this.end_time)){
            // 当前时间属于评分时间范围内
            this.queryClassMonthScoreByCheck(row)
            
          }else{
            this.$message({
              type:'info',
              message:'当前时间不在评分时间范围内！'
            })
          }
        }

      },
      queryClassMonthScoreByCheck(row){
        this.$post(`/base/queryClassMonthScoreByCheck.do`,{
          month_id:this.month_id,
          yearid:this.yearid,
          teacherId:this.teacherId,
          gradeId:row.gradeId
        })
        .then(res=>{
          console.log(res)

          // this.evaluationManagementData = res.data
          this.evaluationManagementScoreDialogVisible = true
  
          let arr = []
          res.data.forEach((item,index1)=>{
            // this.s = item.people_id
            let i = arr.findIndex((jtem)=>jtem.classid == item.classid)
            
              if(i == -1){
                let obj = {
                  classid:item.classid,
                  cname:item.cname,
                  classList:[]
                }
                obj.classList.push(item)
                arr.push(obj)

              }else{
                arr[i].classList.push(item)
              }  
          })

          this.evaluationManagementDetailList = arr

        })
      },
      // 判断当天时间是否属于评分时间范围内
      isIncludedScoreTime(begin_time,end_time){
        let currentTime = new Date().getTime()
        let startTime = new Date(begin_time).getTime()
        let endTime = new Date(end_time).getTime()
        console.log(currentTime,startTime,endTime)
        
        if(currentTime >= startTime && currentTime <= endTime + 86400000){
          return true
        }else{
          return false
        }
      },
      // 提交环境评分
      evaluationManagementScoreConfirm(){
        console.log(this.evaluationManagementDetailList)
        for(let i = 0; i < this.evaluationManagementDetailList.length; i++){
          for(let j = 0; j < this.evaluationManagementDetailList[i].classList.length; j++){
            if(this.evaluationManagementDetailList[i].classList[j].score == null || this.evaluationManagementDetailList[i].classList[j].score == ''){
              this.$message({
                type:'info',
                message:'请评分完毕！'
              })
              return
            }
          }
        }

        // return
        let arr = []
        this.evaluationManagementDetailList.forEach(item=>{
          item.classList.forEach(jtem=>{
            let obj = {
              month_id:this.month_id,
              people_id:jtem.people_id,
              classid:jtem.classid,
              score:jtem.score,
              standard_id:jtem.standard_id
            }
            arr.push(obj)
          })
        })

        // return
        this.$postJson(`/base/insertClassMonthScore.do`,arr)
        .then(res=>{
          console.log(res)
          this.evaluationManagementScoreDialogVisible = false
          this.queryClassMonthGradeList(this.page.currentPage)
        })

      },

    }
}
</script>

<style scoped>
/* .form-height{
  height: 600px;
  overflow-x:auto;
} */

.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center .title{
  text-align: center;
  font-size:20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.center td{
  padding:8px 0px;
  padding-left: 6px;
  text-align: center;
}

.center td /deep/ .el-input{
  width: 85px;
  margin: 0 6px;
}

.center .evaluationScoreStyle{
  padding: 2px 0px;
}

.center .evaluationScoreStyle .classCultureSummaryScoreStyle{
  width: 60px;
}

.evaluationScoreStyle .el-input /deep/ .el-input__inner{
  padding: 3px 5px;
}

.picker {
  margin-left: 10px;
}

.display {
    display: inline-block;
}

.marginRight {
    margin-right: 10px;
}
</style>
